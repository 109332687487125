import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import Mediation from "../assets/images/Menu pic Mediation & Conflict Management .jpg";

export default function MediationSection() {
  const { language } = useContext(LanguageContext); // Accessing the current language

  // Define the content based on the selected language
  const content = {
    en: {
      title: "Mediation & Conflict Management",
      description: `Mediation is a conflict management method for resolving conflicts 
      based on a restorative concept of justice. Restorative justice perceives conflicts 
      as violations of relationships that can be restored. In workplace mediation, the 
      mediator helps the conflicting parties communicate and find a practical agreement 
      on how they can collaborate more effectively.`,
      process: `The process includes an information session on the mediation process, 
      one-on-one discussions with the parties affected by the conflict, a joint mediation 
      session, and a follow-up visit.`,
      steps: [
        "Initial assessment",
        "Information session",
        "Discussions with different parties to conflicts",
        "Joint mediation negotiation",
        "Agreement",
        "Follow-up visit",
      ],
      additionalInfo: `In addition to a mediation process, IMR can offer alternative 
      ways of handling conflicts, such as coaching or conflict management training.`,
      buttonText: "Read More",
    },
    fi: {
      title: "Välitys & Konfliktinhallinta",
      description: `Välitys on konfliktinhallintamenetelmä, joka perustuu palauttavaan oikeuden käsitteeseen. 
      Palauttava oikeus käsittää konfliktit suhteiden rikkomisina, jotka voidaan palauttaa. 
      Työpaikkavälityksessä välimies auttaa ristiriitaisissa osapuolissa kommunikoimaan ja löytämään 
      käytännöllisen sopimuksen siitä, miten he voivat tehdä yhteistyötä tehokkaammin.`,
      process: `Prosessi sisältää tietotilaisuuden välitysprosessista, henkilökohtaisia keskusteluja 
      konfliktin osapuolten kanssa, yhteisen välityssession ja seurantakäynnin.`,
      steps: [
        "Alkutason arviointi",
        "Tietotilaisuus",
        "Keskustelut eri konfliktin osapuolten kanssa",
        "Yhteinen välityspöytäkirja",
        "Sopimus",
        "Seurantakäynti",
      ],
      additionalInfo: `Välitysprosessin lisäksi IMR voi tarjota vaihtoehtoisia tapoja käsitellä 
      konflikteja, kuten coachingia tai konfliktinhallintakoulutusta.`,
      buttonText: "Lue lisää",
    },
  };

  return (
    <section className="container max-w-7xl mx-auto py-16 px-4 lg:px-8 flex flex-col md:flex-row items-start gap-10">
      {/* Left Column: Images */}
      <div className="w-full md:w-1/2 flex justify-center md:justify-end">
        <div className="relative w-full md:w-[500px] h-[300px] md:h-[650px]">
          <img
            src={Mediation}
            alt="Mediation and Conflict Management"
            className="w-full h-full object-cover rounded-lg shadow-md"
          />
        </div>
      </div>

      {/* Right Column: Text Content */}
      <div className="w-full md:w-1/2 md:pl-8">
        <h2 className="text-3xl md:text-4xl font-bold text-black mb-6">
          {content[language]?.title}
        </h2>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed">
          {content[language]?.description}
        </p>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed">
          {content[language]?.process}
        </p>
        {/* Process Steps */}
        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          {content[language]?.steps.join(" > ")}
        </p>
        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          {content[language]?.additionalInfo}
        </p>
      </div>
    </section>
  );
}
