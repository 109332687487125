/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import Coaching from "../assets/images/Menu pic 1to1 Coaching.jpg";

const CoachingSection = () => {
  const { language } = useContext(LanguageContext); // Accessing the current language

  // Define the content based on the selected language
  const content = {
    en: {
      title: "One to One Coaching",
      description: `One to One Coaching is a personalized service that helps individuals
      identify and achieve their professional goals. It involves guidance on
      career planning, job search strategies, and skill development. Career coaching provides support in making
      career transitions, improving workplace performance, and finding
      greater job satisfaction.`,
      additionalInfo: `Whether you're exploring new career paths, returning to work from a
      long absence, finding your strengths as a leader, or seeking work as a
      newcomer in Finland, career coaching provides guidance, strategies,
      and support.`,
      steps: [
        "Initial meeting",
        "Goal setting",
        "Action Plan Development",
        "Ongoing support",
        "Evaluation and Closure",
      ],
      buttonText: "Read More",
    },
    fi: {
      title: "1 to 1 Coaching",
      description: `One to One Coaching on yksilöllinen palvelu, joka auttaa yksilöitä
      tunnistaa ja saavuttaa ammatilliset tavoitteensa. Se sisältää opastuksen
      urasuunnittelu, työnhakustrategiat ja taitojen kehittäminen. Uravalmennus antaa tukea tekemiseen
      urasiirtymät, työpaikan suorituskyvyn parantaminen ja löytäminen
      parempaa työtyytyväisyyttä.`,
      additionalInfo: `Oletpa sitten tutkimassa uusia urapolkuja, palaamassa töihin pitkän poissaolon jälkeen, etsimässä vahvuuksiasi johtajana tai etsimässä töitä uutena tulokkaana Suomessa, uravalmennus tarjoaa ohjausta, strategioita ja tukea.`,
      steps: [
        "Alkutapaaminen",
        "Tavoitteiden asettaminen",
        "Toimintasuunnitelman kehittäminen",
        "Jatkuva tuki",
        "Arviointi ja lopetus",
      ],
      buttonText: "Lue lisää",
    },
  };

  return (
    <div className="flex flex-col md:flex-row md:px-20 mx-auto p-4 py-10">
      <div className="w-full md:w-3/6">
        <img
          src={Coaching}
          alt="One to One Coaching"
          className="rounded-md w-full h-auto object-cover md:h-[90vh] mb-4 md:mb-0"
        />
      </div>
      <div className="w-full md:w-1/2 md:mt-0 md:ml-8 text-center md:text-left">
        <h2 className="text-2xl md:text-5xl font-bold mb-4">
          {content[language].title}
        </h2>
        <p className="text-gray-700 md:text-xl mb-16">
          {content[language].description}
        </p>
        <p className="text-gray-700 md:text-xl mb-12">
          {content[language].additionalInfo}
        </p>
        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          {content[language]?.steps.join(" > ")}
        </p>
      </div>
    </div>
  );
};

export default CoachingSection;
