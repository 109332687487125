/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import client from "api/client";
import { LanguageContext } from "context/LanguageContext";
import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom"; // For navigation in react-router-dom v6
import { forwardRef } from "react";

const Service = forwardRef((props, ref) => {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessService",
      })
      .then((response) => {
        setData(response?.items);
      })
      .catch(console.error);
  }, []);

  const handleCardClick = (id) => {
    navigate(`/services/${id}`); // Navigate to the service details page with the specific id
  };

  return (
    <div ref={ref} className="bg-gray-50 py-10">
      <div className="container mx-auto text-center">
        <Fade direction="right" triggerOnce>
          <span className="text-4xl mb-10 inline-block font-medium text-gray-800">
            {language === "fi" ? "Palvelut" : "Our Services"}
          </span>
        </Fade>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 md:px-10">
          <div
            key={data[0]?.sys?.id}
            className="relative group overflow-hidden bg-white shadow-lg transition-transform duration-500"
            style={{ cursor: "pointer", perspective: "1000px" }}
            onClick={() => handleCardClick(data[0]?.sys?.id)}
          >
            <div className="transform transition-transform duration-500 group-hover:rotate-y-6 group-hover:translate-y-2">
              <img
                src={
                  data[0]?.fields?.img?.fields?.file?.url || "default-image.jpg"
                }
                alt="Service"
                className="w-full h-64 sm:h-72 md:h-80 lg:h-96 object-cover"
              />
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 lg:opacity-0 group-hover:opacity-100 transition duration-500 flex items-center justify-center">
                <h2 className="text-white text-lg lg:text-xl font-semibold text-center">
                  {language === "fi"
                    ? data[0]?.fields?.fiTitle
                    : data[0]?.fields?.enTitle}
                </h2>
              </div>
            </div>
          </div>
          <div
            key={data[3]?.sys?.id}
            className="relative group overflow-hidden bg-white shadow-lg transition-transform duration-500"
            style={{ cursor: "pointer", perspective: "1000px" }}
            onClick={() => handleCardClick(data[3]?.sys?.id)}
          >
            <div className="transform transition-transform duration-500 group-hover:rotate-y-6 group-hover:translate-y-2">
              <img
                src={
                  data[3]?.fields?.img?.fields?.file?.url || "default-image.jpg"
                }
                alt="Service"
                className="w-full h-64 sm:h-72 md:h-80 lg:h-96 object-cover"
              />
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 lg:opacity-0 group-hover:opacity-100 transition duration-500 flex items-center justify-center">
                <h2 className="text-white text-lg lg:text-xl font-semibold text-center">
                  {language === "fi"
                    ? data[3]?.fields?.fiTitle
                    : data[3]?.fields?.enTitle}
                </h2>
              </div>
            </div>
          </div>
          <div
            key={data[1]?.sys?.id}
            className="relative group overflow-hidden bg-white shadow-lg transition-transform duration-500"
            style={{ cursor: "pointer", perspective: "1000px" }}
            onClick={() => handleCardClick(data[1]?.sys?.id)}
          >
            <div className="transform transition-transform duration-500 group-hover:rotate-y-6 group-hover:translate-y-2">
              <img
                src={
                  data[1]?.fields?.img?.fields?.file?.url || "default-image.jpg"
                }
                alt="Service"
                className="w-full h-64 sm:h-72 md:h-80 lg:h-96 object-cover"
              />
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 lg:opacity-0 group-hover:opacity-100 transition duration-500 flex items-center justify-center">
                <h2 className="text-white text-lg lg:text-xl font-semibold text-center">
                  {language === "fi"
                    ? data[1]?.fields?.fiTitle
                    : data[1]?.fields?.enTitle}
                </h2>
              </div>
            </div>
          </div>
          <div
            key={data[2]?.sys?.id}
            className="relative group overflow-hidden bg-white shadow-lg transition-transform duration-500"
            style={{ cursor: "pointer", perspective: "1000px" }}
            onClick={() => handleCardClick(data[2]?.sys?.id)}
          >
            <div className="transform transition-transform duration-500 group-hover:rotate-y-6 group-hover:translate-y-2">
              <img
                src={
                  data[2]?.fields?.img?.fields?.file?.url || "default-image.jpg"
                }
                alt="Service"
                className="w-full h-64 sm:h-72 md:h-80 lg:h-96 object-cover"
              />
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 lg:opacity-0 group-hover:opacity-100 transition duration-500 flex items-center justify-center">
                <h2 className="text-white text-lg lg:text-xl font-semibold text-center">
                  {language === "fi"
                    ? data[2]?.fields?.fiTitle
                    : data[2]?.fields?.enTitle}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Service;
