// src/components/LanguageToggle.js
import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LanguageToggle = () => {
  const { language, switchLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    switchLanguage(event.target.value);
  };

  return (
    <div className="relative inline-block text-left">
      <select
        value={language}
        onChange={handleLanguageChange}
        style={{
          appearance: "none", // Remove default browser styles
          WebkitAppearance: "none", // For Safari compatibility
          MozAppearance: "none", // For Firefox compatibility
          padding: "4px 10px",
          width: "auto",
          fontSize: "0.875rem", // 14px (text-sm)
          fontWeight: 500, // medium
          color: "#ffffff", // white in hex format
          backgroundColor: "#000000", // black in hex format
          border: "none",
          borderRadius: "9999px", // fully rounded
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)", // shadow-sm equivalent
          outline: "none",
          cursor: "pointer",
          textAlign: "center", // Center align text
          lineHeight: "1.5", // Ensure proper height in all browsers
        }}
      >
        <option value="en" style={{ color: "black", textAlign: "center" }}>
          EN
        </option>
        <option value="fi" style={{ color: "black", textAlign: "center" }}>
          FI
        </option>
      </select>
    </div>
  );
};

export default LanguageToggle;
