/* eslint-disable no-irregular-whitespace */
import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import Recruitive from "../assets/Menu pic Services Recruitment & Executive Search.jpg";

function Recurative() {
  const { language } = useContext(LanguageContext); // Accessing the current language

  // Define the content based on the selected language
  const content = {
    en: {
      title: "Recruitment & Executive Search",
      description: `IMR provides tailored recruitment and executive search services to 
      help your organization quickly and efficiently discover professionals and build up a 
      recruitment strategy. IMR's approach combines deep market insights, trust-based 
      communication, and targeted search strategies to identify candidates. IMR collaborates 
      with Executive Search company Winona Group, through which customers gain access to a 
      wide network and assessment tools.`,
      steps: [
        "Initial Assessment",
        "Sourcing",
        "Interviews",
        "Candidate Presentation",
        "Selection",
      ],
    },
    fi: {
      title: "Rekrytointi & Johtajavalinta",
      description: `IMR tarjoaa räätälöityjä rekrytointi- ja johtajavalintapalveluja auttaakseen 
      organisaatiotasi löytämään ammattilaisia nopeasti ja tehokkaasti sekä rakentamaan 
      rekrytointistrategiaa. IMR:n lähestymistapa yhdistää syvät markkinatiedot, luottamukseen 
      perustuvan viestinnän ja kohdennetut hakustrategiat ehdokkaiden tunnistamiseksi. IMR 
      tekee yhteistyötä Executive Search -yrityksen Winona Groupin kanssa, jonka kautta 
      asiakkaat saavat pääsyn laajaan verkostoon ja arviointityökaluihin.`,
      steps: [
        "Alkutason arviointi",
        "Haun käynnistäminen",
        "Haastattelut",
        "Ehdokkaiden esittely",
        "Valinta",
      ],
    },
  };

  return (
    <div className="flex flex-col md:flex-row md:px-20 mx-auto p-4 py-10">
      <div className="flex items-center justify-center w-full md:w-1/2 md:mt-0 md:mr-8 text-center md:text-left">
        <div>
          <h2 className="text-2xl md:text-5xl font-bold mb-4 text-gray-800">
            {content[language].title}
          </h2>
          <p className="text-gray-700 md:text-xl mb-8">
            {content[language].description}
          </p>
          {/* Process Steps */}
          <p className="text-lg text-gray-700 mb-8 leading-relaxed">
            {content[language]?.steps.join(" > ")}
          </p>
        </div>
      </div>
      <div className="w-full md:w-3/6">
        <img
          src={Recruitive}
          alt="Recruitment & Executive Search"
          className="rounded-md w-full h-auto object-cover md:h-[90vh] mb-4 md:mb-0"
        />
      </div>
    </div>
  );
}

export default Recurative;
